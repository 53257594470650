import React from "react";
import { Route } from "react-router-dom";
import { Grommet } from "grommet";

import Home from "./Home";
import Logs from "./Logs";
import Errors from "./Errors";
import Tokens from "./Tokens";
import Dashboard from "./Dashboard";
import Login from "./Login";
import OAuth2Callback from "./OAuth2Callback";
import MenuHome from "./MegaMenu/components/MenuHome";
import { AuthenticationProvider, AuthenticatedRoute, Roles } from "../auth";

const customTheme = {
    global: {
        colors: {
            control: {
                dark: "light-4",
                light: "light-4",
            },
        },
    },
    anchor: {
        fontWeight: 200,
        hover: {
            textDecoration: "none",
        },
    },
};

export default class App extends React.Component {
    render() {
        return (
            <Grommet full theme={customTheme}>
                <AuthenticationProvider>
                    <AuthenticatedRoute exact path="/" component={Home} />
                    <AuthenticatedRoute
                        path="/logs/:id"
                        component={Logs}
                        role={Roles.SYNC}
                    />
                    <AuthenticatedRoute
                        path="/errors/:id"
                        component={Errors}
                        role={Roles.SYNC}
                    />
                    <AuthenticatedRoute
                        path="/tokens"
                        component={Tokens}
                        role={Roles.SYNC}
                    />
                    <AuthenticatedRoute
                        path="/dashboard"
                        component={Dashboard}
                        role={Roles.SYNC}
                    />
                    <AuthenticatedRoute
                        path="/megamenu"
                        component={MenuHome}
                        role={Roles.MENU}
                    />
                    <Route
                        path="/login"
                        render={props => (
                            <Login
                                {...props}
                                url={"/oauth2callback"}
                                handleLogin={this.handleLogin}
                            />
                        )}
                    />
                    <Route path="/oauth2callback" component={OAuth2Callback} />
                </AuthenticationProvider>
            </Grommet>
        );
    }
}
