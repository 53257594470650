import React from "react";

import { Box, Button } from "grommet";

import { AddCircle } from "grommet-icons";

export default class AddWidget extends React.Component {
    render() {
        return (
            <Box width="250px" height="250px" align="center" justify="center">
                <Button
                    plain={true}
                    onClick={() => {
                        this.props.onOpen(null, [this.props.parentIndex]);
                    }}
                    label="Click to add widget"
                />
                <Button
                    plain={true}
                    onClick={() => {
                        this.props.onOpen(null, [this.props.parentIndex]);
                    }}
                    icon={<AddCircle size="medium" />}
                />
            </Box>
        );
    }
}
