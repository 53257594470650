import React from "react";

import { Box, Layer, Text } from "grommet";

const spinAnimation = `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;

const loaderStyle = {
    animationName: "spin",
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
    display: "block",
    alignSelf: "center",
    width: "150px",
    height: "150px",
    border: "16px solid #f3f3f3",
    borderRadius: "50%",
    borderTop: "16px solid #00C781",
};

export default class DataFetchModal extends React.Component {
    render() {
        return (
            <Layer position="center" modal>
                <Box alignContent="center" gap="large" pad="xlarge">
                    <Box>
                        <style children={spinAnimation} />
                        <div style={loaderStyle} />
                    </Box>
                    {this.props.fetch && (
                        <Text>
                            Please wait while we gather store data, this may
                            take a moment...
                        </Text>
                    )}
                    {!this.props.fetch && (
                        <Box width="500px" align="center">
                            <Text>Updating store data...</Text>
                        </Box>
                    )}
                </Box>
            </Layer>
        );
    }
}
