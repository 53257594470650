import axios from "axios";

export function buildOptionSet(type, data) {
    const toParse =
        type == "Collection" || type == "Featured Collection"
            ? data.collections
            : type == "Product" || type == "Featured Product"
            ? data.products
            : type == "Page"
            ? data.pages
            : type == "Blog"
            ? data.blogs
            : null;
    if (toParse) {
        let optionArr = [];
        toParse.forEach(entry => {
            optionArr.push(entry.title);
        });
        return optionArr;
    } else {
        return toParse;
    }
}

export function getProductData(link, value, data, staging, isMidwest) {
    let baseURL = isMidwest 
        ? "www.midwestsupplies.com"
        : "www.northernbrewer.com";
    if (staging) {
        baseURL = isMidwest 
            ? "midwestsupplies-dev.myshopify.com"
            : "northernbrewer-staging.myshopify.com";
    }
    const dataSet =
        link == "Product"
            ? data.products
            : link == "Featured Product"
            ? data.products
            : link == "Collection" || link == "Featured Collection"
            ? data.collections
            : link == "Featured Collection"
            ? data.collections
            : link == "Page"
            ? data.pages
            : link == "Blog"
            ? data.blogs
            : link == "Home Page"
            ? "Home"
            : "Other";
    let url = "";
    let img = null;
    let price = null;
    let comparePrice = null;
    if (Array.isArray(dataSet)) {
        for (let i in dataSet) {
            if (dataSet[i].title == value) {
                let trimmedLink = link.includes("Featured")
                    ? link.replace("Featured ", "")
                    : link;
                url = `https://${baseURL}/${trimmedLink.toLowerCase()}s/${
                    dataSet[i].handle
                }`;
                if (link == "Featured Product") {
                    if (dataSet[i].images) {
                        img = dataSet[i].images[0].src;
                    } else img = "NO IMAGE";
                    price = dataSet[i].variants[0].price;
                    comparePrice = dataSet[i].variants[0].compare_at_price;
                } else if (link == "Featured Collection") {
                    if (dataSet[i].image) {
                        img = dataSet[i].image.src;
                    } else img = "NO IMAGE";
                }
            }
        }
    } else if (dataSet == "Home") {
        url = `https://${baseURL}`;
    } else {
        url = value;
    }
    if (url == "") {
        url = "#";
    }
    data = {
        url: url,
        img: img,
        price: price,
        comparePrice: comparePrice,
    };
    return data;
}

export function getChildrenFromAsset(themeHTML, staging, isMidwest) {
    let baseURL = isMidwest 
        ? "www.midwestsupplies.com"
        : "www.northernbrewer.com";
    if (staging) {
        baseURL = isMidwest 
            ? "midwestsupplies-dev.myshopify.com"
            : "northernbrewer-staging.myshopify.com";
    }
    const parseLinkType = (url, hasImage = false) => {
        let checkedUrl = url ? url : "";
        let linkType =
            checkedUrl == `https://${baseURL}`
                ? "Home Page"
                : checkedUrl.includes(`https://${baseURL}/products`)
                ? "Product"
                : checkedUrl.includes(`https://${baseURL}/products`)
                ? "Featured Product"
                : checkedUrl.includes(`https://${baseURL}/collections`)
                ? "Collection"
                : checkedUrl.includes(`https://${baseURL}/collections`)
                ? "Featured Collection"
                : checkedUrl.includes(`https://${baseURL}/pages`)
                ? "Page"
                : checkedUrl.includes(`https://${baseURL}/blogs`)
                ? "Blog"
                : "Other";
        let final = linkType;
        if (hasImage) {
            if (linkType == "Product" || linkType == "Collection") {
                final = `Featured ${linkType}`;
            }
        }
        return final;
    };
    let children = [];
    let el = document.createElement("html");
    el.innerHTML = themeHTML;
    let menuItems = el.getElementsByClassName("nbmega-navitem");
    Array.from(menuItems).forEach(menuItem => {
        let child = {};
        child.children = [];
        let childData = menuItem.childNodes[0];
        child.color = childData.classList.contains("nbmega-text-red")
            ? "#CE0E2D"
            : "#000000";
        child.img = null;
        child.itemValue = childData.getAttribute("data-item-value");
        child.price = null;
        child.title = childData.textContent;
        child.url = childData.getAttribute("href");
        child.linkType = parseLinkType(child.url);
        if (menuItem.childNodes[1]) {
            let subMenuChild = menuItem.childNodes[1];
            let subMenuWidgets = subMenuChild.getElementsByTagName("div");
            Array.from(subMenuWidgets).forEach((widget, i) => {
                if (
                    !widget.classList.contains(
                        "nbmega-navitem--submenu-title-container"
                    ) &&
                    !widget.classList.contains(
                        "nbmega-navitem--submenu-back-container"
                    )
                ) {
                    if (widget.getElementsByTagName("img").length) {
                        let widgetChild = {};
                        widgetChild.children = [];
                        let widgetChildData = widget.childNodes[1];
                        widgetChild.color = widgetChildData.classList.contains(
                            "nbmega-text-red"
                        )
                            ? "#CE0E2D"
                            : "#000000";
                        widgetChild.img = widget
                            .getElementsByTagName("img")[0]
                            .getAttribute("data-src");
                        widgetChild.itemValue = widgetChildData.getAttribute(
                            "data-item-value"
                        );
                        widgetChild.price = widget.getElementsByClassName(
                            "nbmega-navitem--submenu-img--price"
                        ).length
                            ? widget
                                  .getElementsByClassName(
                                      "nbmega-navitem--submenu-img--price"
                                  )[0]
                                  .innerHTML.replace("$", "")
                            : null;
                        widgetChild.comparePrice = widget.getElementsByClassName(
                            "nbmega-navitem--submenu-img--compareprice"
                        ).length
                            ? widget
                                  .getElementsByClassName(
                                      "nbmega-navitem--submenu-img--compareprice"
                                  )[0]
                                  .innerHTML.replace("$", "")
                            : null;
                        widgetChild.showPrice = widgetChild.price
                            ? true
                            : false;
                        widgetChild.title = widgetChildData.textContent;
                        widgetChild.url = widgetChildData.getAttribute("href");
                        widgetChild.linkType = parseLinkType(
                            widgetChild.url,
                            true
                        );
                        child.children.push(widgetChild);
                    } else {
                        let widgetChild = {};
                        widgetChild.children = [];
                        let widgetChildData = widget.childNodes[0];
                        widgetChild.color = widgetChildData.classList.contains(
                            "nbmega-text-red"
                        )
                            ? "#CE0E2D"
                            : "#000000";
                        widgetChild.img = null;
                        widgetChild.itemValue = widgetChildData.getAttribute(
                            "data-item-value"
                        );
                        widgetChild.price = null;
                        widgetChild.comparePrice = null;
                        widgetChild.title = widgetChildData.textContent;
                        widgetChild.url = widgetChildData.getAttribute("href");
                        widgetChild.linkType = parseLinkType(widgetChild.url);
                        if (widget.childNodes[1]) {
                            Array.from(widget.childNodes[1].childNodes).forEach(
                                (link, i) => {
                                    if (i > 1) {
                                        let linkChild = {};
                                        linkChild.children = [];
                                        let linkChildData = link.childNodes[0];
                                        linkChild.color = linkChildData.classList.contains(
                                            "nbmega-text-red"
                                        )
                                            ? "#CE0E2D"
                                            : "#000000";
                                        linkChild.img = null;
                                        linkChild.itemValue = linkChildData.getAttribute(
                                            "data-item-value"
                                        );
                                        linkChild.price = null;
                                        linkChild.title =
                                            linkChildData.textContent;
                                        linkChild.url = linkChildData.getAttribute(
                                            "href"
                                        );
                                        linkChild.linkType = parseLinkType(
                                            linkChild.url
                                        );
                                        widgetChild.children.push(linkChild);
                                    }
                                }
                            );
                        }
                        child.children.push(widgetChild);
                    }
                }
            });
        }
        children.push(child);
    });
    return children;
}

export function getShopifyAsset(staging, id, isMidwest) {
    return axios
        .get(`/api/shopify/asset?staging=${staging}&id=${id}&midwest=${isMidwest}`)
        .then(r => {
            return r.data;
        })
        .catch(e => {
            return "<div></div>";
        });
}

export function getShopifyData(staging, isMidwest) {
    return axios.get(`/api/shopify/storedata?staging=${staging}&midwest=${isMidwest}`).then(r => {
        return r.data;
    });
}

export function getShopifyThemes(isMidwest) {
    return axios.get(`/api/shopify/themes?midwest=${isMidwest}`).then(r => {
        return r.data;
    });
}

export function updateAssetFromChildren(
    children,
    id,
    themes,
    staging,
    checked,
    isMidwest
) {
    let element = document.createElement("nav");
    element.setAttribute("role", "navigation");
    element.setAttribute("class", "nbmega-container");
    let fragment = document.createDocumentFragment(); // create our fragment from data
    let list = document.createElement("ul"); // the top nav ul
    list.setAttribute("class", "nbmega-navitems");
    children.forEach(child => {
        let lineItem = document.createElement("li"); // create the header nav category
        lineItem.setAttribute("class", "nbmega-navitem");
        let link = document.createElement("a"); // create link from category
        if (child.url !== "null" && child.url !== "#") {
            link.setAttribute("href", child.url);
        }
        if (child.color == "#CE0E2D") {
            link.setAttribute("class", "nbmega-text-red");
        }
        link.innerHTML = child.title;
        link.setAttribute("data-item-value", child.itemValue);
        lineItem.appendChild(link); // add the link to the header nav category
        if (child.children.length) {
            lineItem.classList.add("has-dropdown");
            let subMenu = document.createElement("ul"); // if the link has children create the submenu
            subMenu.setAttribute("class", "nbmega-navitem--submenu");
            let subMenuBox = document.createElement("li");
            let subMenuBackButton = document.createElement("div");
            subMenuBackButton.setAttribute(
                "class",
                "nbmega-navitem--submenu-back-container"
            );
            let subMenuBackButtonLink = document.createElement("a");
            subMenuBackButtonLink.setAttribute(
                "class",
                "nbmega-navitem--submenu-back"
            );
            subMenuBackButtonLink.innerHTML = "BACK";
            subMenuBackButtonLink.setAttribute("href", "#");
            subMenuBackButton.appendChild(subMenuBackButtonLink);
            subMenuBox.appendChild(subMenuBackButton);
            let subMenuTitleBar = document.createElement("div");
            subMenuTitleBar.setAttribute(
                "class",
                "nbmega-navitem--submenu-title-container nbmega-navitem--submenu-vis-override"
            );
            let subMenuTitleBarLink = document.createElement("a");
            subMenuTitleBarLink.setAttribute(
                "class",
                "nbmega-navitem--submenu-title nbmega-navitem--submenu-vis-override"
            );
            subMenuTitleBarLink.innerHTML = child.title;
            if (child.url !== "null" && child.url !== "#") {
                subMenuTitleBarLink.setAttribute("href", child.url);
            }
            subMenuTitleBar.appendChild(subMenuTitleBarLink);
            subMenuBox.appendChild(subMenuTitleBar);
            child.children.forEach(subChild => {
                if (subChild.img) {
                    // if it's an image block, create the image
                    let subMenuImgBlock = document.createElement("div");
                    subMenuImgBlock.setAttribute(
                        "class",
                        "nbmega-navitem--submenu-imgblock nbmega-navitem--submenu-vis-override"
                    );
                    let subMenuImgLink = document.createElement("a");
                    if (subChild)
                        subMenuImgLink.setAttribute("href", subChild.url); // add the image itself
                    let subMenuImgBlockImg = document.createElement("img");
                    subMenuImgBlockImg.setAttribute("data-src", subChild.img);
                    subMenuImgBlockImg.setAttribute("height", "220px");
                    subMenuImgBlockImg.setAttribute("width", "220px");
                    subMenuImgBlockImg.setAttribute("class", "lazy_load");
                    subMenuImgLink.appendChild(subMenuImgBlockImg);
                    subMenuImgBlock.appendChild(subMenuImgLink); // add the link containing the image to the image block
                    let subMenuTitleLink = document.createElement("a"); //create the title
                    subMenuTitleLink.setAttribute(
                        "class",
                        "nbmega-navitem--submenu-img--title"
                    );
                    if (subChild.url !== "null" && subChild.url !== "#") {
                        subMenuTitleLink.setAttribute("href", subChild.url);
                    }
                    subMenuTitleLink.innerHTML = subChild.title;
                    subMenuTitleLink.setAttribute(
                        "data-item-value",
                        subChild.itemValue
                    );
                    subMenuImgBlock.appendChild(subMenuTitleLink); // add the title to the image block
                    if (subChild.showPrice) {
                        if (subChild.comparePrice) {
                            let subMenuImgComparePrice = document.createElement(
                                "p"
                            );
                            subMenuImgComparePrice.setAttribute(
                                "class",
                                "nbmega-navitem--submenu-img--compareprice"
                            );
                            subMenuImgComparePrice.innerHTML = `$${
                                subChild.comparePrice
                            }`;
                            subMenuImgBlock.appendChild(subMenuImgComparePrice); // add the price to the image block
                        }
                        if (subChild.price) {
                            // if theres a price field, create that
                            let subMenuImgPrice = document.createElement("p");
                            subMenuImgPrice.setAttribute(
                                "class",
                                "nbmega-navitem--submenu-img--price"
                            );
                            subMenuImgPrice.innerHTML = `$${subChild.price}`;
                            subMenuImgBlock.appendChild(subMenuImgPrice); // add the price to the image block
                        }
                    }
                    subMenuBox.appendChild(subMenuImgBlock); //  add the image block back to the containing li
                } else {
                    // if not an image block, create the linklist
                    let subMenuLinkListContainer = document.createElement(
                        "div"
                    );
                    let subMenuHeader = document.createElement("a"); // create the header
                    subMenuHeader.setAttribute(
                        "class",
                        "nbmega-navitem--submenu-linklist--header"
                    );
                    if (
                        subChild.url !== "null" &&
                        subChild.url !== "#" &&
                        subChild.url !== null
                    ) {
                        subMenuHeader.setAttribute("href", subChild.url);
                    } else {
                        subMenuHeader.classList.add("nolink");
                    }
                    if (subChild.color == "#CE0E2D") {
                        subMenuHeader.classList.add("nbmega-text-red");
                    }
                    if (subChild.children.length) {
                        subMenuHeader.classList.add("has-linklist");
                    }
                    subMenuHeader.innerHTML = subChild.title;
                    subMenuHeader.setAttribute(
                        "data-item-value",
                        subChild.itemValue
                    );
                    subMenuLinkListContainer.appendChild(subMenuHeader); // add the header to the link list
                    if (subChild.children.length) {
                        // create the link list
                        let subMenuLinkList = document.createElement("ul");
                        subMenuLinkList.setAttribute(
                            "class",
                            "nbmega-navitem--submenu-linklist"
                        );
                        let linkListBackButton = document.createElement("a");
                        linkListBackButton.setAttribute(
                            "class",
                            "nbmega-navitem--submenu-linklist-back"
                        );
                        linkListBackButton.innerHTML = "BACK";
                        linkListBackButton.setAttribute(
                            "aria-label",
                            "Close Button"
                        );
                        subMenuLinkList.appendChild(linkListBackButton);
                        let linkListTitleBar = document.createElement("a");
                        linkListTitleBar.setAttribute(
                            "class",
                            "nbmega-navitem--submenu-linklist-title"
                        );
                        linkListTitleBar.innerHTML = subChild.title;
                        if (
                            subChild.url !== "null" &&
                            subChild.url !== "#" &&
                            subChild.url !== null
                        ) {
                            linkListTitleBar.setAttribute("href", subChild.url);
                        }
                        subMenuLinkList.appendChild(linkListTitleBar);
                        subChild.children.forEach(link => {
                            let subMenuLinkListLine = document.createElement(
                                "li"
                            );
                            let subMenuLinkListLink = document.createElement(
                                "a"
                            );
                            if (link.url !== "null" && link.url !== "#") {
                                subMenuLinkListLink.setAttribute(
                                    "href",
                                    link.url
                                );
                            }
                            subMenuLinkListLink.innerHTML = link.title;
                            if (link.color == "#CE0E2D") {
                                subMenuLinkListLink.classList.add(
                                    "nbmega-text-red"
                                );
                            }
                            subMenuLinkListLink.setAttribute(
                                "data-item-value",
                                link.itemValue
                            );
                            subMenuLinkListLine.appendChild(
                                subMenuLinkListLink
                            );
                            subMenuLinkList.appendChild(subMenuLinkListLine);
                        });
                        subMenuLinkListContainer.appendChild(subMenuLinkList);
                    }
                    subMenuBox.appendChild(subMenuLinkListContainer); // add the linklist container back to the containing li
                }
            });
            subMenu.appendChild(subMenuBox); //add the li back the the submenu ul
            lineItem.appendChild(subMenu); // add the submenu to the li
        }
        list.appendChild(lineItem); // add the category to the header nav
    });
    fragment.appendChild(list);
    element.appendChild(fragment);
    const data = {
        asset: { key: "snippets/NB-megamenu.liquid", value: element.outerHTML },
    };
    const headers = {
        "X-XSRFToken": document.cookie.match("\\b_xsrf=([^;]*)\\b")[1],
    };
    return axios
        .post(
            "/api/shopify/asset",
            {
                assetData: data,
                id: id,
                themes: themes,
                staging: staging,
                checked: checked,
                isMidwest: isMidwest
            },
            {
                headers: headers,
            }
        )
        .then(r => {
            return r.status;
        });
}
