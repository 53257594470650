import React from "react";
import { Box, Button, DataTable, Text, Heading } from "grommet";

import { bindAll, jsonGET, jsonPOST } from "../utils";

import StandardHeader from "./StandardHeader";

export default class Tokens extends React.Component {
    componentDidMount() {
        let url = new URL(window.location.href);

        if (url.searchParams.has("code")) {
            jsonGET("/api/vend/auth", {
                code: url.searchParams.get("code"),
                domain_prefix: url.searchParams.get("domain_prefix"),
            }).then(response => {
                this.getTokens();
            });
            return;
        }

        this.getTokens();
    }

    constructor(props) {
        super(props);
        this.state = {
            token_data: [],
            error: "",
        };

        bindAll(this, ["componentDidMount", "getTokens"]);
    }

    getTokens() {
        jsonGET("/api/tokens").then(response => {
            if (response.status == "success") {
                this.setState({
                    token_data: response.data,
                });
                console.log(this.state.token_data);
            } else {
                this.setState({
                    error: "Failed receiving token data",
                });
            }
        });
    }

    render() {
        const columns = [
            {
                property: "name",
                header: <Text>Manager Name</Text>,
                primary: true,
            },
            {
                property: "token",
                header: "Current Token",
            },
            {
                property: "started",
                header: "Started",
                render: datum => (
                    <Text>{datum.started ? "True" : "False"}</Text>
                ),
            },
            {
                property: "healthy",
                header: "Healthy",
                render: datum => (
                    <Text>{datum.healthy ? "True" : "False"}</Text>
                ),
            },
            {
                property: "re_activate",
                header: "Re-Activate",
                render: datum => (
                    <Box pad={{ vertical: "xsmall" }}>
                        {<Button label="Activate" href={datum.url} />}
                    </Box>
                ),
            },
        ];

        return (
            <Box>
                <StandardHeader />

                <Box alignSelf="center" align="center">
                    <Box
                        border="bottom"
                        margin="small"
                        width="xlarge"
                        align="start"
                        direction="row"
                    >
                        <Heading margin="none" level="2">
                            Tokens
                        </Heading>
                    </Box>

                    <Box
                        align="center"
                        width="xlarge"
                        margin={{ left: "small", right: "small" }}
                    >
                        <DataTable
                            alignSelf="stretch"
                            sortable={true}
                            columns={columns}
                            data={this.state.token_data}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }
}
