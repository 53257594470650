import React from "react";
import {
    Box,
    Button,
    CheckBox,
    Grommet,
    Layer,
    Heading,
    RadioButton,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text,
    TextInput,
} from "grommet";

import { buildOptionSet, getProductData } from "../helpers/menuHelpers";

let globalOptions = null;

const updateGlobalOptions = (option, data) => {
    globalOptions = buildOptionSet(option, data);
};

const customGrommet = {
    layer: {
        zIndex: 20,
    },
};
export default class CatModal extends React.Component {
    constructor() {
        super();
        this.state = {
            color: "",
            title: "",
            linkType: "",
            isLinkSelected: false,
            itemValue: "",
            itemOptions: globalOptions,
            showPrice: false,
        };
        this.handleTitleInput = this.handleTitleInput.bind(this);
        this.handleLinkInput = this.handleLinkInput.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleColorSelect = this.handleColorSelect.bind(this);
        this.handleShowPrice = this.handleShowPrice.bind(this);
    }

    componentDidMount() {
        if (this.props.activeData) {
            updateGlobalOptions(
                this.props.activeData[0].linkType,
                this.props.storeData
            );
            this.setState({
                color: this.props.activeData[0].color,
                title: this.props.activeData[0].title,
                linkType: this.props.activeData[0].linkType,
                isLinkSelected: true,
                itemValue: this.props.activeData[0].itemValue,
                itemOptions: globalOptions,
                showPrice: this.props.activeData[0].showPrice,
            });
        }
    }

    handleAdd() {
        const productData = getProductData(
            this.state.linkType,
            this.state.itemValue,
            this.props.storeData,
            this.props.staging,
            this.props.midwest
        );
        let child = {
            color: this.state.color,
            children: this.props.activeData
                ? this.props.activeData[0].children
                : [],
            title: this.state.title,
            itemValue: this.state.itemValue,
            url:
                productData.url == this.state.itemValue
                    ? this.state.itemValue
                    : productData.url,
            img: productData.img,
            price: productData.price,
            comparePrice: productData.comparePrice,
            linkType: this.state.linkType,
            showPrice: this.state.showPrice,
        };
        this.props.addOrUpdateMenuChildren(child);
        this.props.onClose();
        globalOptions = null;
    }

    handleColorSelect(e) {
        this.setState({ color: e.target.value });
    }

    handleLinkInput(e) {
        this.setState({ itemValue: e.target.value });
    }

    handleLinkSelect(option) {
        updateGlobalOptions(option, this.props.storeData);
        let itemValue = option == "Home Page" ? "Home" : "";
        this.setState({
            linkType: option,
            isLinkSelected: true,
            itemValue: itemValue,
            itemOptions: globalOptions,
        });
    }

    handleShowPrice() {
        this.setState({
            showPrice: this.state.showPrice ? false : true,
        });
    }

    handleTitleInput(e) {
        this.setState({ title: e.target.value });
    }

    render() {
        const linkOptions =
            this.props.subMenuData && this.props.subMenuData.length == 1
                ? [
                      "Home Page",
                      "Collection",
                      "Featured Collection",
                      "Product",
                      "Featured Product",
                      "Page",
                      "Blog",
                      "Other",
                  ]
                : [
                      "Home Page",
                      "Collection",
                      "Product",
                      "Page",
                      "Blog",
                      "Other",
                  ];
        const {
            color,
            title,
            linkType,
            itemValue,
            isLinkSelected,
            itemOptions,
            showPrice,
        } = this.state;
        return (
            <Grommet theme={customGrommet}>
                <Layer
                    position="center"
                    modal
                    onClickOutside={() => this.props.onClose()}
                    onEsc={() => this.props.onClose()}
                >
                    <Box pad="medium" gap="small" width="xlarge">
                        <Heading level={3} margin="none">
                            Add Menu Heading
                        </Heading>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableCell scope="col" border="bottom">
                                        Title
                                    </TableCell>
                                    <TableCell scope="col" border="bottom">
                                        Link
                                    </TableCell>
                                    <TableCell scope="col" border="bottom" />
                                    {linkType == "Featured Product" && (
                                        <TableCell scope="col" border="bottom">
                                            Show Price?
                                        </TableCell>
                                    )}
                                    <TableCell scope="col" border="bottom">
                                        Color
                                    </TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                <TableRow>
                                    <TableCell scope="row">
                                        <TextInput
                                            onChange={this.handleTitleInput}
                                            value={title}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            id="catModalLinkSelect"
                                            name="select"
                                            default={linkOptions[0]}
                                            value={linkType}
                                            options={linkOptions}
                                            onChange={({ option }) =>
                                                this.handleLinkSelect(option)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {Array.isArray(itemOptions) && (
                                            <Select
                                                active={isLinkSelected}
                                                disabled={
                                                    linkType[0] == "Home Page"
                                                }
                                                id="catModalItemSelect"
                                                name="select"
                                                placeholder="Search"
                                                value={itemValue}
                                                options={itemOptions}
                                                onChange={({ option }) =>
                                                    this.setState({
                                                        itemValue: option,
                                                    })
                                                }
                                                onClose={() =>
                                                    this.setState({
                                                        itemOptions: globalOptions,
                                                    })
                                                }
                                                onSearch={text => {
                                                    const exp = new RegExp(
                                                        text,
                                                        "i"
                                                    );
                                                    this.setState({
                                                        itemOptions: globalOptions.filter(
                                                            o => exp.test(o)
                                                        ),
                                                    });
                                                }}
                                            />
                                        )}
                                        {!linkType && (
                                            <TextInput disabled={true} />
                                        )}
                                        {linkType == "Home Page" && (
                                            <TextInput disabled={true} />
                                        )}
                                        {linkType == "Other" && (
                                            <TextInput
                                                value={itemValue}
                                                onChange={this.handleLinkInput}
                                            />
                                        )}
                                    </TableCell>
                                    {linkType == "Featured Product" && (
                                        <TableCell>
                                            <CheckBox
                                                name="radio"
                                                checked={showPrice}
                                                onChange={this.handleShowPrice}
                                                toggle={true}
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <Box align="start" gap="small">
                                            <RadioButton
                                                label="Black"
                                                name="radio"
                                                value="#000000"
                                                checked={color === "#000000"}
                                                onChange={
                                                    this.handleColorSelect
                                                }
                                                {...this.props}
                                            />
                                            <RadioButton
                                                label="Red"
                                                name="radio"
                                                value="#CE0E2D"
                                                checked={color === "#CE0E2D"}
                                                onChange={
                                                    this.handleColorSelect
                                                }
                                                {...this.props}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Box
                            as="footer"
                            gap="small"
                            direction="row"
                            align="center"
                            justify="end"
                            pad={{ top: "medium", bottom: "small" }}
                        >
                            <Button
                                label={this.props.activeData ? "Update" : "Add"}
                                color="dark-3"
                                onClick={() => this.handleAdd()}
                            />
                            {this.props.activeData && (
                                <Button
                                    label={
                                        <Text color="white">
                                            <strong>Delete</strong>
                                        </Text>
                                    }
                                    onClick={() => this.props.deleteMenuChild()}
                                    primary
                                    color="status-critical"
                                />
                            )}
                            <Button
                                label={
                                    <Text color="status-critical">
                                        <strong>Cancel</strong>
                                    </Text>
                                }
                                onClick={() => this.props.onClose()}
                                color="status-critical"
                            />
                        </Box>
                    </Box>
                </Layer>
            </Grommet>
        );
    }
}
