import React from "react";
import { Box } from "grommet";

import Widget from "./Widget";
import AddWidget from "./AddWidget";

export default class SubMenuBox extends React.Component {
    componentWillUnmount() {
        if (this.props.children && this.props.children.length > 1) {
            this.props.updateAllChildren(this.props.children, [
                this.props.index,
            ]);
        }
    }
    onDragStart(e, index) {
        this.dragItem = this.props.children[index];
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    }

    onDragOver(index) {
        const dragOverItem = this.props.children[index];
        if (this.dragItem === dragOverItem) {
            return;
        }
        let items = this.props.children.filter(item => item !== this.dragItem);
        items.splice(index, 0, this.dragItem);
        this.props.updateChildren(items);
    }

    render() {
        return (
            <Box
                width="1530px"
                pad="small"
                background="light-2"
                gap="small"
                direction="row"
                align="center"
                wrap={true}
                overflow="auto"
            >
                <ul
                    style={{
                        textAlign: "center",
                        listStyleType: "none",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        padding: "0px",
                    }}
                >
                    {this.props.children.map((child, i) => {
                        return (
                            <li
                                key={child.title}
                                onDragOver={() => this.onDragOver(i)}
                                style={{
                                    display: "inline-block",
                                    padding: "10px 10px",
                                }}
                            >
                                <div
                                    draggable
                                    onDragStart={e => this.onDragStart(e, i)}
                                >
                                    <Widget
                                        onOpen={this.props.onOpen}
                                        self={child}
                                        index={i}
                                        updateAllChildren={
                                            this.props.updateAllChildren
                                        }
                                        parentIndex={this.props.index}
                                    />
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <AddWidget
                    onOpen={this.props.onOpen}
                    parentIndex={this.props.index}
                />
            </Box>
        );
    }
}
