import React from "react";

import { Box, Button, Text } from "grommet";

import { Edit } from "grommet-icons";

export default class SubMenuLinkBox extends React.Component {
    componentWillUnmount() {
        if (this.props.children && this.props.children.length > 1) {
            this.props.updateAllChildren(this.props.children, [
                this.props.parentIndex,
                this.props.index,
            ]);
        }
    }

    onDragStart(e, index) {
        this.dragItem = this.props.children[index];
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    }

    onDragOver(index) {
        const dragOverItem = this.props.children[index];
        if (this.dragItem === dragOverItem) {
            return;
        }
        let items = this.props.children.filter(item => item !== this.dragItem);
        items.splice(index, 0, this.dragItem);
        this.props.updateChildren(items);
    }

    render() {
        return (
            <Box align="start">
                <ul
                    style={{ listStyleType: "none", paddingInlineStart: "0px" }}
                >
                    {this.props.children.map((child, i) => {
                        return (
                            <li
                                key={child.title}
                                onDragOver={() => this.onDragOver(i)}
                                style={{ padding: "0px 10px" }}
                            >
                                <div
                                    draggable
                                    onDragStart={e => this.onDragStart(e, i)}
                                >
                                    <Box align="center" direction="row">
                                        <Text color={child.color} size="small">
                                            {child.title}
                                        </Text>
                                        <Box>
                                            <div
                                                onClick={() =>
                                                    this.props.onOpen(
                                                        [child, i],
                                                        [
                                                            this.props
                                                                .parentIndex,
                                                            this.props.index,
                                                        ]
                                                    )
                                                }
                                                style={{
                                                    padding: "0px 12px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Edit size="small" />
                                            </div>
                                        </Box>
                                    </Box>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </Box>
        );
    }
}
