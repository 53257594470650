import React from "react";

import { Box, Button, Select, Text, CheckBox } from "grommet";

import {
    getChildrenFromAsset,
    getShopifyThemes,
    getShopifyAsset,
} from "../helpers/menuHelpers";

import { bindAll } from '../../../utils';

import SaveModal from "./SaveModal";
import DataFetchModal from "./DataFetchModal";

const environments = {
    STAGING: "STAGING",
    PRODUCTION: "PRODUCTION"
}

const brands = {
    NORTHERN: "NORTHERN BREWER",
    MIDWEST: "MIDWEST SUPPLIES"
}

export default class TopNav extends React.Component {
    constructor() {
        super();
        this.state = {
            themes: {},
            value: "",
            themeHTML: "",
            themeID: "",
            open: false,
            open2: false,
            environment: environments.PRODUCTION,
            brand: brands.NORTHERN
        };
        bindAll(this, ["onClose", "onOpen2", "onClose2"]);
    }

    componentDidMount() {
        this.getThemes(this.state.brand == brands.MIDWEST);
    }

    onOpen() {
        this.setState({
            open: true,
        });
    }

    onOpen2() {
        this.setState({
            open2: true,
        });
    }

    onClose() {
        this.setState({
            activeData: null,
            open: false,
            subMenuData: null,
        });
    }

    onClose2() {
        this.setState({
            open2: false,
        });
    }

    getThemes(selectedBrand) {
        getShopifyThemes(selectedBrand).then(res => {
            this.setState({
                themes: res.data,
            });
        });
    }

    handleSelect(option) {
        let env = option.includes("STAGING") ? environments.STAGING : environments.PRODUCTION;
        let stageTrim = option.includes("STAGING -")
            ? option.replace("STAGING - ", "")
            : option;
        let liveTrim = stageTrim.includes("LIVE THEME")
            ? stageTrim.substring(0, stageTrim.length - 13)
            : stageTrim;
        let id = "";
        let selectedThemeSet =
            env == environments.STAGING
                ? this.state.themes.staging
                : this.state.themes.production;
        for (let i in selectedThemeSet) {
            if (selectedThemeSet[i].name == liveTrim) {
                this.props.updateTheme(
                    selectedThemeSet[i].id,
                    env == environments.STAGING,
                    this.state.brand == brands.MIDWEST
                );
                id = selectedThemeSet[i].id;
                getShopifyAsset(env == "STAGING", selectedThemeSet[i].id, this.state.brand == brands.MIDWEST).then(
                    res => {
                        let themeHTML = res.data ? res.data.asset.value : res;
                        let children = getChildrenFromAsset(
                            themeHTML,
                            env == "STAGING",
                            this.state.brand == brands.MIDWEST
                        );
                        this.props.setChildrenOnThemeChange(children);
                        this.setState({
                            themeHTML: themeHTML,
                        });
                    }
                );
                this.setState({
                    value: option,
                    environment: env,
                    themeID: id,
                });
            }
        }
    }

    selectStore = event => {
        let selectedBrand = event.target.checked ? brands.MIDWEST : brands.NORTHERN
        this.setState({ value: "", brand: selectedBrand });
        this.getThemes(selectedBrand == brands.MIDWEST);
        this.props.setChildrenOnThemeChange([]);
    }

    render() {
        let options = [];
        const { open, open2, environment, value, brand } = this.state;
        if (Object.entries(this.state.themes).length) {
            let productionOptions = [];
            this.state.themes.production.forEach(theme => {
                theme.role == "main"
                    ? productionOptions.unshift(`${theme.name} - LIVE THEME`)
                    : productionOptions.push(theme.name);
            });
            let stagingOptions = [];
            this.state.themes.staging.forEach(theme => {
                theme.role == "main"
                    ? stagingOptions.unshift(
                          `STAGING - ${theme.name} - LIVE THEME`
                      )
                    : stagingOptions.push(`STAGING - ${theme.name}`);
            });
            options = productionOptions.concat(stagingOptions);
        }
        return (
            <Box>
                <Box
                    background={environment == "STAGING" ? "dark-2" : "light-2"}
                    fill="horizontal"
                    align="center"
                    justify="between"
                    pad={{ vertical: "small", horizontal: "large" }}
                    direction="row"
                >
                    <Box
                        align="center"
                    >
                        <Box 
                            align="center"
                            direction="row"
                            pad={{ horizontal: "medium", vertical: "small" }}
                        >
                            <Box pad={{ horizontal: "small" }}>
                                <Text>
                                    Northern Brewer
                                </Text>
                            </Box>
                            <CheckBox
                                {...this.props}
                                label="Midwest Supplies"
                                checked={brand == brands.MIDWEST}
                                onChange={(event) => this.selectStore(event)}
                                toggle
                            >
                            </CheckBox>
                        </Box>
                        <Select
                            id="select"
                            name="select"
                            placeholder="Select Theme"
                            value={value}
                            options={options}
                            onChange={({ option }) => this.handleSelect(option)}
                        />
                    </Box>
                    <Box pad="0% 15% 0% 0%">
                        <Text weight="bold">{brand} {environment}</Text>
                    </Box>
                    <Button
                        disabled={this.state.value == ""}
                        label={
                            <Text color="white">
                                <strong>Save</strong>
                            </Text>
                        }
                        onClick={() => {
                            this.onOpen();
                        }}
                        primary
                        color="status-ok"
                    />
                </Box>
                {open && (
                    <SaveModal
                        onClose={this.onClose}
                        menuBarChildren={this.props.menuBarChildren}
                        themeID={this.state.themeID}
                        themes={
                            environment == "STAGING"
                                ? this.state.themes.staging
                                : this.state.themes.production
                        }
                        staging={environment}
                        isMidwest={this.state.brand == brands.MIDWEST}
                        onOpen2={this.onOpen2}
                        onClose2={this.onClose2}
                        onOpen3={this.onOpen3}
                    />
                )}
                {open2 && <DataFetchModal fetch={false} />}
            </Box>
        );
    }
}
