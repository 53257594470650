import React from "react";
import { Grommet } from "grommet";

import MenuMaker from "./MenuMaker";

export default class MenuHome extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedTheme: null,
            staging: null,
            midwest: null
        };
        this.updateTheme = this.updateTheme.bind(this);
    }

    updateTheme(id, isStaging, isMidwest) {
        this.setState({
            selectedTheme: id,
            staging: isStaging,
            midwest: isMidwest
        });
    }

    render() {
        return (
            <Grommet full plain>
                <MenuMaker
                    updateTheme={this.updateTheme}
                    selectedTheme={this.state.selectedTheme}
                    staging={this.state.staging}
                    midwest={this.state.midwest}
                />
            </Grommet>
        );
    }
}
