import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Roles = {
    ADMIN: "admin",
    MENU: "menu",
    SYNC: "sync",
};

const AuthContext = React.createContext({
    loggedIn: false,
    email: null,
    name: null,
    roles: [],
    loginUser: user_data => {},
    logoutUser: () => {},
});

class AuthenticationProvider extends React.Component {
    constructor(props) {
        super(props);

        this.loginUser = user_data => {
            cookies.set("clientUserEmail", user_data["email"]);
            cookies.set("clientUserName", user_data["name"]);
            cookies.set("clientUserRoles", user_data["roles"].join("|"));
            cookies.set("_xsrf", user_data["_xsrf"]);

            this.setState(state => ({
                loggedIn: true,
                email: user_data["email"],
                name: user_data["name"],
                roles: user_data["roles"],
            }));
        };

        this.removeCookies = () => {
            cookies.remove("clientUserEmail");
            cookies.remove("clientUserName");
            cookies.remove("clientUserRoles");

            // Remove server side cookies as well when logging out
            cookies.remove("user");
            cookies.remove("roles");
            cookies.remove("_xsrf");
        };

        this.logoutUser = () => {
            this.removeCookies();
            this.setState(state => ({
                loggedIn: false,
                email: null,
                name: null,
                roles: [],
            }));
        };

        if (
            cookies.get("user") != undefined &&
            cookies.get("clientUserRoles") != undefined
        ) {
            var roles = cookies.get("clientUserRoles");
            roles = roles.split("|");
            this.state = {
                loggedIn: true,
                email: cookies.get("clientUserEmail"),
                name: cookies.get("clientUserName"),
                roles: roles,
                loginUser: this.loginUser,
                logoutUser: this.logoutUser,
            };
        } else {
            this.removeCookies();
            this.state = {
                loggedIn: false,
                email: null,
                name: null,
                roles: [],
                loginUser: this.loginUser,
                logoutUser: this.logoutUser,
            };
        }
    }

    render() {
        return (
            <AuthContext.Provider value={this.state}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

const AuthenticatedRoute = ({ component: Component, role: role, ...rest }) => {
    return (
        <AuthContext.Consumer>
            {({ loggedIn, email, name, roles, loginUser }) => (
                <Route
                    {...rest}
                    render={props =>
                        loggedIn &&
                        (role == undefined || roles.indexOf(role) != -1) ? (
                            <Component {...props} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: props.location },
                                }}
                            />
                        )
                    }
                />
            )}
        </AuthContext.Consumer>
    );
};

export { AuthContext, AuthenticationProvider, AuthenticatedRoute, Roles };
