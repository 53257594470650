import React from "react";
import { Box, Clock, Heading, Meter, Stack, Text } from "grommet";

import { bindAll, jsonGET, jsonPOST } from "../utils";

import StandardHeader from "./StandardHeader";
import WindowChart from "./WindowChart";

export default class Dashboard extends React.Component {
    componentDidMount() {
        this.getDashLogList();
        setInterval(this.getDashLogList, 100000);
    }

    constructor(props) {
        super(props);
        this.state = {
            log_data: [],
            test_log_data: null,
            previous_item: {},
            error_log_total: 0,
            last_hour_errors: 0,
            todays_errors: 0,
            error: "",
        };
        this.getDashLogList = this.getDashLogList.bind(this);
        this.loadErrorCharts = this.loadErrorCharts.bind(this);
        this.loadWindowChart = this.loadWindowChart.bind(this);
        this.toReadableDate = this.toReadableDate.bind(this);
        this.todaysReadableDate = this.todaysReadableDate.bind(this);
        this.toTimestamp = this.toTimestamp.bind(this);
        this.getItems = this.getItems.bind(this);
    }

    toTimestamp(strDate) {
        var datum = Date.parse(strDate);
        return datum / 1000;
    }

    toReadableDate(stampDate) {
        var temp_date = new Date(stampDate);
        var dd = temp_date.getDate();
        var mm = temp_date.getMonth() + 1;
        var yy = temp_date
            .getFullYear()
            .toString()
            .substr(-2);
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        temp_date = mm + "/" + dd + "/" + yy;
        return temp_date;
    }

    todaysReadableDate() {
        var temp_date = new Date();
        var dd = temp_date.getDate();
        var mm = temp_date.getMonth() + 1;
        var yy = temp_date
            .getFullYear()
            .toString()
            .substr(-2);
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        temp_date = mm + "/" + dd + "/" + yy;
        return temp_date;
    }

    loadWindowChart() {
        var chart_array = [];
        var chart_data = {};
        var total_daily_errors = 0;
        var last_daily_runtime = 0;

        this.state.log_data.data.map((item, i) => {
            var todays_date = this.todaysReadableDate();
            var runtime_date = item.runtime.split("M ");
            var now = Math.floor(new Date().getTime() / 1000);
            var hour_ago = now - 3600;
            var yesterday = now - 24 * 3600;
            var previous_item_date = this.toReadableDate(
                this.state.previous_item.runtime
            );

            if (
                runtime_date[1] == previous_item_date &&
                runtime_date[1] != todays_date
            ) {
                total_daily_errors += item.errors;
                last_daily_runtime = this.toTimestamp(runtime_date[1]);
                chart_data = {
                    time: last_daily_runtime * 1000,
                    value: total_daily_errors,
                };
            } else if (
                runtime_date[1] != previous_item_date &&
                runtime_date[1] == todays_date
            ) {
                chart_data = {
                    time: last_daily_runtime * 1000,
                    value: total_daily_errors,
                };
                chart_array.push(chart_data);
                total_daily_errors = 0;
                last_daily_runtime = 0;
            } else if (this.state.log_data.data.length === i + 1) {
                if (last_daily_runtime == 0) {
                    chart_data = {
                        time: this.toTimestamp(todays_date) * 1000,
                        value: 0,
                    };
                } else {
                    chart_data = {
                        time: last_daily_runtime * 1000,
                        value: total_daily_errors,
                    };
                }
                chart_array.push(chart_data);
                total_daily_errors = 0;
                last_daily_runtime = 0;
            } else if (runtime_date[1] == todays_date) {
                total_daily_errors += item.errors;
                last_daily_runtime = this.toTimestamp(runtime_date[1]);
                chart_data = {
                    time: last_daily_runtime * 1000,
                    value: total_daily_errors,
                };
            } else {
                if (last_daily_runtime == 0) {
                    chart_data = {
                        time: this.toTimestamp(todays_date) * 1000,
                        value: 0,
                    };
                } else {
                    chart_data = {
                        time: last_daily_runtime * 1000,
                        value: total_daily_errors,
                    };
                }
                chart_array.push(chart_data);
                total_daily_errors = 0;
                last_daily_runtime = 0;
            }

            if (chart_array.length == 0) {
                this.setState({
                    test_log_data: null,
                });
            } else {
                this.setState({
                    test_log_data: chart_array,
                });
            }
            this.setState({
                previous_item: item,
            });
        });
    }

    loadErrorCharts() {
        var daily_val = 0;
        var hourly_val = 0;
        var todays_val = 0;
        this.state.log_data.data.map((item, i) => {
            var todays_date = new Date();
            var dd = todays_date.getDate();
            var mm = todays_date.getMonth() + 1;
            var yy = todays_date
                .getFullYear()
                .toString()
                .substr(-2);
            if (dd < 10) {
                dd = "0" + dd;
            }
            if (mm < 10) {
                mm = "0" + mm;
            }
            todays_date = mm + "/" + dd + "/" + yy;
            var runtime_date = item.runtime.split("M ");
            var utc_runtime = this.toTimestamp(item.runtime);
            var now = Math.floor(new Date().getTime() / 1000);
            var hour_ago = now - 3600;
            var yesterday = now - 24 * 3600;

            if (utc_runtime >= hour_ago) {
                hourly_val += item.errors;
            }

            if (utc_runtime >= yesterday) {
                daily_val += item.errors;
            }

            if (runtime_date[1] == todays_date) {
                todays_val += item.errors;
            }

            this.setState({
                error_log_total: daily_val,
                last_hour_errors: hourly_val,
                todays_errors: todays_val,
            });
        });
    }

    getItems() {
        this.state.log_data.data.map((item, i) => {
            return item, i;
        });
    }

    getDashLogList() {
        jsonGET("/api/dash").then(response => {
            if (response.status == "success") {
                this.setState({
                    log_data: response.data,
                });
                this.loadErrorCharts();
                // this.loadWindowChart()
            } else {
                this.setState({
                    error: "Failed fetching data",
                });
            }
        });
    }

    render() {
        var daily_error = this.state.error_log_total;
        var hour_error = this.state.last_hour_errors;
        var todays_error = this.state.todays_errors;
        var log_data = this.state.test_log_data;

        return (
            <Box>
                <StandardHeader />
                <Box
                    animation={{
                        type: "fadeIn",
                        duration: 2000,
                    }}
                    alignSelf="center"
                    align="center"
                >
                    <Box
                        border="bottom"
                        margin="small"
                        width="xlarge"
                        height="xxsmall"
                        align="start"
                        direction="row"
                    >
                        <Box align="start" basis="1/2" alignSelf="start">
                            <Heading margin="none" level="2">
                                Dashboard
                            </Heading>
                        </Box>
                        <Box align="end" basis="1/2" alignSelf="center">
                            <Clock type="digital" />
                        </Box>
                    </Box>

                    <Box
                        margin="small"
                        width="xlarge"
                        align="start"
                        direction="row"
                    >
                        <Box
                            basis="1/3"
                            align="end"
                            alignSelf="end"
                            margin={{ top: "small" }}
                        >
                            <Stack anchor="center">
                                <Meter
                                    type="circle"
                                    size="small"
                                    background="light-2"
                                    values={[
                                        {
                                            value: hour_error,
                                            color:
                                                hour_error > 50
                                                    ? "accent-2"
                                                    : "accent-1",
                                        },
                                    ]}
                                />
                                <Box
                                    direction="row"
                                    align="center"
                                    pad={{ bottom: "medium" }}
                                >
                                    <Text size="xlarge" weight="bold">
                                        {hour_error}
                                    </Text>
                                </Box>
                                <Box
                                    direction="row"
                                    align="center"
                                    margin={{ top: "medium", bottom: "xsmall" }}
                                >
                                    <Text size="small" weight="bold">
                                        Errors Last
                                    </Text>
                                </Box>
                                <Box
                                    direction="row"
                                    align="center"
                                    margin={{ top: "large" }}
                                >
                                    <Text size="small" weight="bold">
                                        60 Minutes
                                    </Text>
                                </Box>
                            </Stack>
                        </Box>

                        <Box
                            basis="1/3"
                            align="center"
                            alignSelf="center"
                            margin={{ top: "small" }}
                        >
                            <Stack anchor="center">
                                <Meter
                                    type="circle"
                                    size="small"
                                    background="light-2"
                                    values={[
                                        {
                                            value: daily_error,
                                            color:
                                                daily_error > 100
                                                    ? "accent-2"
                                                    : "accent-1",
                                        },
                                    ]}
                                />
                                <Box
                                    direction="row"
                                    align="center"
                                    pad={{ bottom: "medium" }}
                                >
                                    <Text size="xlarge" weight="bold">
                                        {daily_error}
                                    </Text>
                                </Box>
                                <Box
                                    direction="row"
                                    align="center"
                                    margin={{ top: "medium", bottom: "xsmall" }}
                                >
                                    <Text size="small" weight="bold">
                                        Errors Last
                                    </Text>
                                </Box>
                                <Box
                                    direction="row"
                                    align="center"
                                    margin={{ top: "large" }}
                                >
                                    <Text size="small" weight="bold">
                                        24 Hours
                                    </Text>
                                </Box>
                            </Stack>
                        </Box>

                        <Box
                            basis="1/3"
                            align="start"
                            alignSelf="start"
                            margin={{ top: "small" }}
                        >
                            <Stack anchor="center">
                                <Meter
                                    type="circle"
                                    size="small"
                                    background="light-2"
                                    values={[
                                        {
                                            value: todays_error,
                                            color:
                                                todays_error > 150
                                                    ? "accent-2"
                                                    : "accent-1",
                                        },
                                    ]}
                                />
                                <Box
                                    direction="row"
                                    align="center"
                                    pad={{ bottom: "medium" }}
                                >
                                    <Text size="xlarge" weight="bold">
                                        {todays_error}
                                    </Text>
                                </Box>
                                <Box
                                    direction="row"
                                    align="center"
                                    margin={{ top: "medium", bottom: "xsmall" }}
                                >
                                    <Text size="small" weight="bold">
                                        Errors
                                    </Text>
                                </Box>
                                <Box
                                    direction="row"
                                    align="center"
                                    margin={{ top: "large" }}
                                >
                                    <Text size="small" weight="bold">
                                        Today Only
                                    </Text>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>

                {this.state.test_log_data != null ? (
                    <Box>
                        <WindowChart data={log_data} max={200} />
                    </Box>
                ) : null}
            </Box>
        );
    }
}
