import React from "react";
import { Cycle } from "grommet-icons";
import { Box } from "grommet";

import { bindAll, jsonGET } from "../utils";
import { AuthContext } from "../auth";

class OAuth2Callback extends React.Component {
    componentDidMount() {
        let url = new URL(window.location.href);
        jsonGET("/api/oauth/login", {
            code: url.searchParams.get("code"),
        })
            .then(response => {
                if (response.status == "success") {
                    let { loginUser } = this.context;
                    loginUser(response.data);
                    this.props.history.push(url.searchParams.get("state"));
                } else {
                    this.props.history.push("/login");
                }
            })
            .catch(error => {
                this.props.history.push("/login");
            });
    }

    render() {
        return (
            <Box align="center" justify="center">
                <Cycle size="xlarge" />
            </Box>
        );
    }
}
OAuth2Callback.contextType = AuthContext;

export default OAuth2Callback;
