import React from "react";
import { Box, Grommet } from "grommet";

import { getShopifyData } from "../helpers/menuHelpers";
import StandardHeader from "../../StandardHeader";
import CatModal from "./CatModal";
import MenuBar from "./MenuBar";
import TopNav from "./TopNav";
import DataFetchModal from "./DataFetchModal";

const customTheme = {
    global: {
        colors: {
            control: {
                dark: "light-4",
                light: "light-4",
            },
        },
    },
    anchor: {
        fontWeight: 200,
        hover: {
            textDecoration: "none",
        },
    },
};

export default class MenuMaker extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
            open2: false,
            storeData: {},
            menuBarChildren: [],
            subMenuData: null,
            activeData: null,
        };
        this.subCatBox = React.createRef();
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.addOrUpdateMenuChildren = this.addOrUpdateMenuChildren.bind(this);
        this.deleteMenuChild = this.deleteMenuChild.bind(this);
        this.updateMenuChildrenOrder = this.updateMenuChildrenOrder.bind(this);
        this.setChildrenOnThemeChange = this.setChildrenOnThemeChange.bind(
            this
        );
    }

    componentDidUpdate(prevprops) {
        if ((this.props.staging !== prevprops.staging) || this.props.midwest !== prevprops.midwest) {
            this.onOpen2();
            getShopifyData(this.props.staging, this.props.midwest).then(res => {
                this.setState({ storeData: res.data });
                this.onClose2();
            });
        }
    }

    onOpen(activeData = null, subMenuData = null) {
        this.setState({
            activeData: activeData,
            open: true,
            subMenuData: subMenuData,
        });
    }

    onOpen2() {
        this.setState({ open2: true });
    }

    onClose() {
        this.setState({
            activeData: null,
            open: false,
            subMenuData: null,
        });
    }

    onClose2() {
        this.setState({ open2: false });
    }

    addOrUpdateMenuChildren(child) {
        let children = this.state.menuBarChildren;
        if (this.state.activeData) {
            if (this.state.subMenuData) {
                this.state.subMenuData.length == 1
                    ? (children[this.state.subMenuData[0]].children[
                          this.state.activeData[1]
                      ] = child)
                    : (children[this.state.subMenuData[0]].children[
                          this.state.subMenuData[1]
                      ].children[this.state.activeData[1]] = child);
            } else {
                children[this.state.activeData[1]] = child;
            }
        } else if (this.state.subMenuData) {
            this.state.subMenuData.length == 1
                ? children[this.state.subMenuData[0]].children.push(child)
                : children[this.state.subMenuData[0]].children[
                      this.state.subMenuData[1]
                  ].children.push(child);
        } else {
            children.push(child);
        }
        this.setState({
            menuBarChildren: children,
        });
    }

    deleteMenuChild() {
        let children = this.state.menuBarChildren;
        if (this.state.activeData) {
            if (this.state.subMenuData) {
                this.state.subMenuData.length == 1
                    ? children[this.state.subMenuData[0]].children.splice(
                          this.state.activeData[1],
                          1
                      )
                    : children[this.state.subMenuData[0]].children[
                          this.state.subMenuData[1]
                      ].children.splice(this.state.activeData[1], 1);
            } else {
                children.splice(this.state.activeData[1], 1);
            }
        }
        this.setState({
            menuBarChildren: children,
        });
        this.onClose();
    }

    setChildrenOnThemeChange(children) {
        this.setState({ menuBarChildren: children });
    }

    updateMenuChildrenOrder(children, positionData = null) {
        if (positionData) {
            let updatedChildren = this.state.menuBarChildren;
            if (positionData.length == 1) {
                updatedChildren[positionData[0]].children = children;
            } else if (positionData.length == 2) {
                updatedChildren[positionData[0]].children[
                    positionData[1]
                ].children = children;
            }
            this.setState({ menuBarChildren: updatedChildren });
        } else {
            this.setState({ menuBarChildren: children });
        }
    }

    render() {
        const { open, open2 } = this.state;
        return (
            <Grommet theme={customTheme}>
                <StandardHeader />
                <TopNav
                    updateTheme={this.props.updateTheme}
                    setChildrenOnThemeChange={this.setChildrenOnThemeChange}
                    menuBarChildren={this.state.menuBarChildren}
                />
                <Box>
                    <Box
                        align="center"
                        direction="row"
                        justify="center"
                        border="horizontal"
                    >
                        <Box
                            id="nb-megamenu--container"
                            align="center"
                            pad="small"
                            width="1530px"
                            direction="row"
                            wrap
                        >
                            <MenuBar
                                children={this.state.menuBarChildren}
                                onOpen={this.onOpen}
                                updateMenuChildrenOrder={
                                    this.updateMenuChildrenOrder
                                }
                                target={this.subCatBox}
                                selectedTheme={this.props.selectedTheme}
                            />
                        </Box>
                    </Box>
                    <Box
                        alignSelf="center"
                        width="1330px"
                        ref={this.subCatBox}
                    />
                    {open && (
                        <CatModal
                            activeData={this.state.activeData}
                            addOrUpdateMenuChildren={
                                this.addOrUpdateMenuChildren
                            }
                            deleteMenuChild={this.deleteMenuChild}
                            onClose={this.onClose}
                            storeData={this.state.storeData}
                            subMenuData={this.state.subMenuData}
                            staging={this.props.staging}
                            midwest={this.props.midwest}
                        />
                    )}
                    {open2 && <DataFetchModal fetch={true} />}
                </Box>
            </Grommet>
        );
    }
}
