import React from "react";

import { Box, Button, DropButton, Grommet } from "grommet";

import { Down, Edit } from "grommet-icons";

import SubMenuBox from "./SubMenuBox";

export default class MenuHeading extends React.Component {
    constructor() {
        super();
        this.state = {
            children: [],
        };
        this.updateChildren = this.updateChildren.bind(this);
    }

    componentDidMount() {
        if (this.props.self.children) {
            this.setState({ children: this.props.self.children });
        }
    }

    addChild(child) {
        let children = this.state.children;
        children.splice(children.length - 1, 0, child);
        this.setState({ children: children });
    }

    updateChildren(children) {
        this.setState({ children: children });
    }

    render() {
        const { open } = this.state;
        return (
            <div>
                <Grommet>
                    <Box animation="fadeIn" direction="row" gap="small">
                        <DropButton
                            plain={true}
                            label={
                                <div
                                    className="nb-megamenu--top-category"
                                    style={{ color: this.props.self.color }}
                                >
                                    <Box
                                        direction="row"
                                        gap="xsmall"
                                        align="center"
                                    >
                                        {this.props.self.title}
                                        <Down
                                            size="small"
                                            color="#000000"
                                            weight="bold"
                                        />
                                    </Box>
                                </div>
                            }
                            open={open}
                            onClose={() => this.setState({ open: undefined })}
                            dropAlign={{ top: "top" }}
                            dropTarget={this.props.target.current}
                            dropContent={
                                <SubMenuBox
                                    children={this.state.children}
                                    index={this.props.index}
                                    onOpen={this.props.onOpen}
                                    updateChildren={this.updateChildren}
                                    updateAllChildren={
                                        this.props.updateMenuChildrenOrder
                                    }
                                />
                            }
                        />
                        <Button
                            plain={true}
                            icon={<Edit size="small" />}
                            onClick={() =>
                                this.props.onOpen([
                                    this.props.self,
                                    this.props.index,
                                ])
                            }
                        />
                    </Box>
                </Grommet>
            </div>
        );
    }
}
