import React from "react";
import axios from "axios";
import { Box, Button, CheckBox, Anchor, Text, Menu, Grommet } from "grommet";

import { jsonGET, jsonPOST } from "../utils";

import { AuthContext, Roles } from "../auth";

import StandardHeader from "./StandardHeader";

import MenuHome from "./MegaMenu/components/MenuHome";

export default class Home extends React.Component {
    componentDidMount() {
        this.getSyncList();
        setInterval(this.getSyncList, 10000);
    }

    constructor(props) {
        super(props);
        this.state = {
            sync_job: [],
            error: "",
        };
        this.getSyncList = this.getSyncList.bind(this);
        this.run = this.run.bind(this);
    }

    getSyncList() {
        jsonGET("/api/sync").then(response => {
            if (response.status == "success") {
                this.setState({
                    sync_job: response.data,
                });
                console.log("sync jobs: ", response.data);
            } else {
                this.setState({
                    error: "Failed receiving sync job data",
                });
            }
        });
    }

    run(id) {
        var body = {
            sync_job: this.state.sync_job[id].id,
            //add optional endpoints here
        };
        jsonPOST("/api/sync", body).then(response => {
            if (response.status == "success") {
                console.log("Sync Jobs Successfully Loaded");
            }
        });
    }

    render() {
        const checkBoxTheme = {
            checkbox: {
                border: {
                    color: {
                        light: "neutral-2",
                    },
                    radius: "2px",
                },
                color: {
                    light: "neutral-2",
                },
                check: {
                    color: {
                        light: "status-ok",
                    },
                },
            },
        };

        const customButtonTheme = {
            button: {
                border: {
                    radius: "4px",
                    color: "#2196f3",
                },
                padding: {
                    vertical: "1px",
                    horizontal: "10px",
                },
                primary: {
                    color: "#2196f3",
                },
                extend: props => {
                    let extraStyles = "";
                    if (props.primary) {
                        extraStyles = `
              text-transform: uppercase;
            `;
                    }
                    return `
            font-size: 12px;
            font-weight: bold;
            font-family: Helvetica;
            color: white;

            ${extraStyles}
          `;
                },
            },
        };

        return (
            <AuthContext.Consumer>
                {({ roles }) => (
                    <Box>
                        {roles.includes(Roles.MENU) && roles.length == 1 ? (
                            <MenuHome />
                        ) : null}
                        {roles.includes(Roles.ADMIN) ||
                        roles.includes(Roles.SYNC) ? (
                            <Box>
                                <StandardHeader />

                                {this.state.sync_job.map((item, i) => {
                                    return (
                                        <Box
                                            key={i}
                                            align="center"
                                            direction="column"
                                            justify="center"
                                            margin={{ top: "xsmall" }}
                                            fill="horizontal"
                                            animation={{
                                                type: "fadeIn",
                                                duration: 1000,
                                            }}
                                        >
                                            <Box
                                                align="start"
                                                direction="row"
                                                justify="start"
                                                border={{
                                                    side: "top, right, left",
                                                    size: "small",
                                                }}
                                                round={{
                                                    size: "small",
                                                    corner: "top",
                                                }}
                                                width="xlarge"
                                                animation={{
                                                    type: "fadeIn",
                                                    duration: 1000,
                                                }}
                                            >
                                                <Box
                                                    align="center"
                                                    basis="1/4"
                                                    alignSelf="center"
                                                    direction="row"
                                                    gap="small"
                                                    justify="start"
                                                    content-align="center"
                                                >
                                                    <Text
                                                        color="dark-1"
                                                        size="medium"
                                                        margin={{
                                                            left: "medium",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Text>
                                                </Box>
                                                <Box
                                                    align="center"
                                                    basis="1/4"
                                                    alignSelf="center"
                                                    direction="row"
                                                    gap="small"
                                                    justify="start"
                                                >
                                                    <Text
                                                        color="dark-1"
                                                        size="medium"
                                                    >
                                                        Runs: {item.runs}
                                                    </Text>
                                                </Box>
                                                <Box
                                                    align="end"
                                                    basis="1/4"
                                                    alignSelf="center"
                                                    direction="row"
                                                    gap="small"
                                                    justify="center"
                                                >
                                                    <Text
                                                        color="dark-1"
                                                        size="medium"
                                                    >
                                                        {item.last_run}
                                                    </Text>
                                                </Box>
                                                <Box
                                                    basis="1/4"
                                                    align="end"
                                                    justify="end"
                                                    alignSelf="end"
                                                    margin={{ right: "medium" }}
                                                >
                                                    <Grommet
                                                        theme={
                                                            customButtonTheme
                                                        }
                                                    >
                                                        <Button
                                                            margin="xsmall"
                                                            size="small"
                                                            color="neutral-1"
                                                            primary={true}
                                                            label="Run"
                                                            onClick={() =>
                                                                this.run(i)
                                                            }
                                                        />
                                                    </Grommet>
                                                </Box>
                                            </Box>

                                            {item.endpoints.map(
                                                (endpoint, idx) => {
                                                    return (
                                                        <Box
                                                            key={i}
                                                            align="start"
                                                            direction="row"
                                                            justify="start"
                                                            background={{
                                                                color:
                                                                    "light-2",
                                                            }}
                                                            border={{
                                                                size: "small",
                                                            }}
                                                            round={{
                                                                size: "xxsmall",
                                                                corner:
                                                                    "bottom",
                                                            }}
                                                            width="xlarge"
                                                            pad="xsmall"
                                                            animation={{
                                                                type: "fadeIn",
                                                                duration: 1000,
                                                            }}
                                                        >
                                                            <Box
                                                                align="start"
                                                                alignSelf="center"
                                                                basis="1/4"
                                                                direction="row"
                                                                justify="start"
                                                                content-align="center"
                                                                pad={{
                                                                    left:
                                                                        "large",
                                                                }}
                                                            >
                                                                <Grommet
                                                                    theme={
                                                                        checkBoxTheme
                                                                    }
                                                                >
                                                                    <CheckBox
                                                                        checked={
                                                                            true
                                                                        }
                                                                        onChange={event => {
                                                                            /* event.target.checked */
                                                                        }}
                                                                    />
                                                                </Grommet>
                                                            </Box>
                                                            <Box
                                                                align="start"
                                                                alignSelf="center"
                                                                basis="1/4"
                                                                justify="start"
                                                            >
                                                                <Text
                                                                    color="dark-1"
                                                                    size="medium"
                                                                >
                                                                    {
                                                                        endpoint.name
                                                                    }
                                                                </Text>
                                                            </Box>
                                                            <Box
                                                                align="start"
                                                                basis="1/4"
                                                                alignSelf="start"
                                                                direction="row"
                                                                margin="small"
                                                                justify="start"
                                                                content-align="center"
                                                            />
                                                            <Box
                                                                align="end"
                                                                basis="1/4"
                                                                justify="end"
                                                                alignSelf="center"
                                                            >
                                                                <Box
                                                                    background={{
                                                                        color:
                                                                            "neutral-1",
                                                                    }}
                                                                    round="medium"
                                                                    pad={{
                                                                        horizontal:
                                                                            "xsmall",
                                                                    }}
                                                                    align="center"
                                                                    width={{
                                                                        min:
                                                                            "30px",
                                                                    }}
                                                                    margin={{
                                                                        right:
                                                                            "medium",
                                                                    }}
                                                                >
                                                                    <Text
                                                                        color="light-1"
                                                                        size="large"
                                                                    >
                                                                        {
                                                                            endpoint.processing
                                                                        }
                                                                    </Text>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    );
                                                }
                                            )}
                                        </Box>
                                    );
                                })}
                            </Box>
                        ) : null}
                    </Box>
                )}
            </AuthContext.Consumer>
        );
    }
}
