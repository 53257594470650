function bindAll(target, functions) {
    for (var i in functions) {
        target[functions[i]] = target[functions[i]].bind(target);
    }
}

function getCookie(name) {
    var r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
    return r ? r[1] : undefined;
}

// NOTE: Params can be left off the call
function jsonGET(url, params) {
    if (params != undefined) {
        url = new URL(
            url,
            window.location.protocol + "//" + window.location.host
        );
        url.search = new URLSearchParams(params);
    }

    return fetch(url, {
        credentials: "include",
        method: "GET",
        headers: {
            Accept: "application/json",
        },
    }).then(response => {
        return response.json();
    });
}

function jsonPOST(url, body) {
    body._xsrf = getCookie("_xsrf");
    return fetch(url, {
        credentials: "include",
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-XSRFToken": body._xsrf,
        },
        body: JSON.stringify(body),
    }).then(response => {
        return response.json();
    });
}

const normalizeValues = values =>
    (values || []).map((value, index) => {
        if (Array.isArray(value)) {
            return { value };
        }
        if (typeof value === "number") {
            return { value: [index, value] };
        }
        return value;
    });

const normalizeBounds = (bounds, values) => {
    let result = bounds;
    if (!result) {
        result = [[0, 1], [0, 1]];
        (values || []).forEach(value => {
            result[0][0] = Math.min(result[0][0], value.value[0]);
            result[0][1] = Math.max(result[0][1], value.value[0]);
            result[1][0] = Math.min(result[1][0], value.value[1]);
            result[1][1] = Math.max(result[1][1], value.value[1]);
        });
    }
    return result;
};

export { bindAll, jsonGET, jsonPOST, normalizeValues, normalizeBounds };
