import React from "react";

import { Box, Button, Grommet, Image, Text } from "grommet";

import { AddCircle, Edit } from "grommet-icons";

import SubMenuLinkBox from "./SubMenuLinkBox";

export default class Widget extends React.Component {
    constructor() {
        super();
        this.state = {
            children: [],
        };
        this.updateChildren = this.updateChildren.bind(this);
    }
    componentDidMount() {
        if (this.props.self.children) {
            this.setState({ children: this.props.self.children });
        }
    }
    updateChildren(children) {
        this.setState({ children: children });
    }

    render() {
        return (
            <Grommet>
                <Box width="260px" gap="xsmall">
                    {this.props.self.img && (
                        <Box>
                            <Box align="center" justify="center" pad="xsmall">
                                {this.props.self.img !== "NO IMAGE" && (
                                    <Box height="200px" width="200px">
                                        <Image
                                            fit="contain"
                                            src={this.props.self.img}
                                        />
                                    </Box>
                                )}
                                {this.props.self.img == "NO IMAGE" && (
                                    <Box
                                        height="200px"
                                        width="200px"
                                        align="center"
                                        justify="center"
                                    >
                                        <Text>NO IMAGE FOUND</Text>
                                    </Box>
                                )}
                                <Box
                                    align="center"
                                    justify="center"
                                    pad="xsmall"
                                    direction="row"
                                >
                                    <Text weight="bold">
                                        {this.props.self.title}
                                    </Text>
                                    <Button
                                        icon={<Edit size="small" />}
                                        onClick={() =>
                                            this.props.onOpen(
                                                [
                                                    this.props.self,
                                                    this.props.index,
                                                ],
                                                [this.props.parentIndex]
                                            )
                                        }
                                    />
                                </Box>
                                {this.props.self.showPrice && (
                                    <Box>
                                        {this.props.self.comparePrice && (
                                            <p
                                                style={{
                                                    textDecoration:
                                                        "line-through",
                                                }}
                                            >
                                                ${this.props.self.comparePrice}
                                            </p>
                                        )}
                                        {this.props.self.price && (
                                            <Text>
                                                ${this.props.self.price}
                                            </Text>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                    {!this.props.self.img && (
                        <Box>
                            <Box
                                align="center"
                                justify="center"
                                border="bottom"
                                pad="xsmall"
                                direction="row"
                            >
                                <Text
                                    weight="bold"
                                    color={this.props.self.color}
                                >
                                    {this.props.self.title}
                                </Text>
                                <Button
                                    icon={<Edit size="small" />}
                                    onClick={() =>
                                        this.props.onOpen(
                                            [this.props.self, this.props.index],
                                            [this.props.parentIndex]
                                        )
                                    }
                                />
                            </Box>
                            <SubMenuLinkBox
                                onOpen={this.props.onOpen}
                                children={this.state.children}
                                index={this.props.index}
                                updateChildren={this.updateChildren}
                                updateAllChildren={this.props.updateAllChildren}
                                parentIndex={this.props.parentIndex}
                            />
                            <Button
                                plain={true}
                                onClick={() => {
                                    this.props.onOpen(null, [
                                        this.props.parentIndex,
                                        this.props.index,
                                    ]);
                                }}
                                icon={<AddCircle size="small" />}
                            />
                        </Box>
                    )}
                </Box>
            </Grommet>
        );
    }
}
