import React from "react";
import { Box, Anchor, Text, Menu } from "grommet";

import { AuthContext, Roles } from "../auth";
import { bindAll, jsonGET } from "../utils";

export default class StandardHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
        };

        bindAll(this, ["componentDidMount"]);
    }

    componentDidMount() {
        jsonGET("/api/syncs/jobs").then(response => {
            if (response.status == "success") {
                this.setState({
                    jobs: response.data,
                });
            }
        });
    }

    render() {
        return (
            <AuthContext.Consumer>
                {({ logoutUser, name, roles }) => (
                    <Box fill={true} align="center">
                        <Box
                            align="center"
                            direction="row"
                            justify="center"
                            background={{ color: "dark-1" }}
                            fill="horizontal"
                        >
                            <Box
                                basis="1/3"
                                direction="row"
                                gap="small"
                                align="center"
                                justify="start"
                            >
                                <Text
                                    color="light-1"
                                    size="large"
                                    margin={{ left: "medium", right: "xlarge" }}
                                >
                                    Theseus
                                </Text>
                            </Box>
                            <Box
                                basis="1/3"
                                direction="row"
                                gap="small"
                                align="center"
                                justify="center"
                            >
                                {roles.includes(Roles.SYNC) ||
                                roles.includes(Roles.ADMIN) ? (
                                    <Anchor
                                        color="light-1"
                                        href="/"
                                        label="Home"
                                        size="medium"
                                        margin={{ horizontal: "medium" }}
                                    />
                                ) : null}
                                {roles.includes(Roles.SYNC) ? (
                                    <>
                                        <Menu
                                            color="light-4"
                                            dropBackground="dark-1"
                                            label="Logs"
                                            items={
                                                this.state.jobs === []
                                                    ? []
                                                    : this.state.jobs.map(
                                                          job => {
                                                              return {
                                                                  label:
                                                                      job.name,
                                                                  href: `/logs/${
                                                                      job.id
                                                                  }`,
                                                              };
                                                          }
                                                      )
                                            }
                                        />
                                        <Menu
                                            dropBackground="dark-1"
                                            label="Errors"
                                            items={
                                                this.state.jobs === []
                                                    ? []
                                                    : this.state.jobs.map(
                                                          job => {
                                                              return {
                                                                  label:
                                                                      job.name,
                                                                  href: `/errors/${
                                                                      job.id
                                                                  }`,
                                                              };
                                                          }
                                                      )
                                            }
                                        />
                                    </>
                                ) : null}
                                {roles.includes(Roles.MENU) ? (
                                    <Anchor
                                        color="light-1"
                                        href="/megamenu"
                                        label="MegaMenu"
                                        size="medium"
                                        margin={{ horizontal: "small" }}
                                    />
                                ) : null}
                                {roles.includes(Roles.ADMIN) ? (
                                    <Anchor
                                        color="light-1"
                                        href="/tokens"
                                        label="Tokens"
                                        size="medium"
                                        margin={{ horizontal: "small" }}
                                    />
                                ) : null}
                                {roles.includes(Roles.ADMIN) ? (
                                    <Anchor
                                        color="light-1"
                                        href="/dashboard"
                                        label="Dashboard"
                                        size="medium"
                                        margin={{ horizontal: "small" }}
                                    />
                                ) : null}
                            </Box>
                            <Box basis="1/3" align="end" justify="center">
                                <Menu
                                    dropBackground="dark-1"
                                    label={name}
                                    items={[
                                        {
                                            label: "Logout",
                                            onClick: () => {
                                                logoutUser();
                                                this.props.history.push(
                                                    "/login"
                                                );
                                            },
                                        },
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
            </AuthContext.Consumer>
        );
    }
}
