import React from "react";
import { Box, Button, Anchor, Text, Grommet } from "grommet";
import { bindAll } from "../utils";

class Pagination extends React.Component {
    render() {
        const pagiButtonTheme = {
            button: {
                border: {
                    color: "#555555",
                },
                primary: {
                    color: "#555555",
                },
            },
        };

        const middleButtonTheme = {
            borderRadius: "0",
        };

        const rightButtonTheme = {
            borderRadius: "0px 18px 18px 0px",
        };

        const leftButtonTheme = {
            borderRadius: "18px 0px 0px 18px",
        };

        const BeginningPageSelector = () => {
            return (
                <Button
                    disabled={beginningDisabled}
                    style={leftButtonTheme}
                    label="<<"
                    onClick={() => {
                        this.props.changePage(1);
                    }}
                />
            );
        };

        const EndPageSelector = () => {
            return (
                <Button
                    disabled={endDisabled}
                    style={rightButtonTheme}
                    label=">>"
                    onClick={() => {
                        this.props.changePage(pages);
                    }}
                />
            );
        };

        var beginningDisabled = false;
        var endDisabled = false;
        var activePage = false;

        var currentPage = this.props.page;

        var pages = Math.ceil(this.props.count / this.props.limit);
        var num_pages = pages > 5 ? 5 : pages;
        var start = 1;

        if (pages - this.props.page <= 3) {
            start = pages - num_pages + 1;
        } else if (this.props.page > 2) {
            start = this.props.page - 2;
        }

        if (this.props.page == 1) {
            beginningDisabled = true;
        }

        if (this.props.page == pages) {
            endDisabled = true;
        }

        if (this.props.page == currentPage) {
            activePage = true;
        }

        var disp_pages = [];
        for (var i = start; i < start + num_pages; i++) {
            disp_pages.push(i);
        }

        return (
            <Grommet theme={pagiButtonTheme}>
                <Box direction="row" alignSelf="center" margin="large">
                    <Box direction="row">
                        {<BeginningPageSelector />}

                        {disp_pages.map((page, i) => {
                            return (
                                <Button
                                    key={i}
                                    style={middleButtonTheme}
                                    label={page}
                                    primary={
                                        page == currentPage ? activePage : false
                                    }
                                    onClick={() => {
                                        this.props.changePage(page);
                                    }}
                                />
                            );
                        })}

                        {<EndPageSelector />}
                    </Box>
                </Box>
            </Grommet>
        );
    }
}

export default Pagination;
