import React from "react";
import {
    Box,
    Button,
    CheckBox,
    Anchor,
    Text,
    Menu,
    DataTable,
    Heading,
    Grommet,
} from "grommet";

import { bindAll, jsonGET, jsonPOST } from "../utils";

import StandardHeader from "./StandardHeader";
import Pagination from "./Pagination";
import TopPagination from "./TopPagination";

const columns = [
    {
        property: "id",
        header: <Text>ID</Text>,
        primary: true,
    },
    {
        property: "runtime",
        header: "Runtime",
    },
    {
        property: "endpoints",
        header: "Endpoints",
    },
    {
        property: "errors",
        header: "Errors",
        align: "center",
    },
    {
        property: "acknowledged",
        header: "Acknowledged",
        align: "center",
    },
];

export default class Logs extends React.Component {
    componentDidMount() {
        this.getLogList(
            this.state.pagination.job,
            this.state.pagination.page,
            this.state.pagination.limit
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            log_data: {},
            pagination: {
                name: "",
                job: this.props.match.params.id,
                page: 1,
                count: 0,
                limit: 15,
            },
            error: "",
        };
        this.getLogList = this.getLogList.bind(this);
    }

    getLogList(job, page, limit) {
        jsonGET("/api/log", { sync_job: job, page: page, limit: limit }).then(
            response => {
                if (response.status == "success") {
                    this.setState({
                        log_data: response.data,
                        pagination: response.data.pagination,
                    });
                } else {
                    this.setState({
                        error: "fail",
                    });
                }
            }
        );
    }

    pageSelect(page) {
        this.setState({
            pagination: {
                name: "Shopify Sales Order Sync",
                page: page,
                limit: this.state.log_data.pagination.limit,
                count: this.state.log_data.pagination.count,
            },
        });
        this.getLogList(
            this.state.pagination.job,
            page,
            this.state.pagination.limit
        );
    }

    render() {
        const globalTheme = {
            global: {
                font: {
                    // family: "Helvetica"
                },
            },
            button: {
                font: {
                    weight: "bold",
                },
                border: {
                    radius: "8px",
                    color: "#2196f3",
                },
                extend: props => {
                    let extraStyles = "";
                    if (props.primary) {
                        extraStyles = `
                text-transform: uppercase;
              `;
                    }
                    return `
              ${extraStyles}
            `;
                },
            },
            datatable: {},
        };

        return (
            <Box>
                <StandardHeader />
                <Grommet theme={globalTheme}>
                    <Box
                        animation={{
                            type: "fadeIn",
                            duration: 2000,
                        }}
                        alignSelf="center"
                        align="center"
                    >
                        <Box
                            border="bottom"
                            margin="small"
                            width="xlarge"
                            align="start"
                        >
                            <Heading margin="none" level="2">
                                {this.state.pagination.name} Logs
                            </Heading>
                        </Box>

                        <TopPagination
                            parent={this}
                            changePage={this.pageSelect.bind(this)}
                            page={this.state.pagination.page}
                            limit={this.state.pagination.limit}
                            count={this.state.pagination.count}
                        />

                        <Box
                            align="center"
                            width="xlarge"
                            margin={{ left: "small", right: "small" }}
                        >
                            <DataTable
                                alignSelf="stretch"
                                sortable={true}
                                columns={columns}
                                data={this.state.log_data.data}
                            />
                        </Box>

                        <Pagination
                            parent={this}
                            changePage={this.pageSelect.bind(this)}
                            page={this.state.pagination.page}
                            limit={this.state.pagination.limit}
                            count={this.state.pagination.count}
                        />
                    </Box>
                </Grommet>
            </Box>
        );
    }
}
