import React from "react";
import { Box, Button, Grommet } from "grommet";
import { FormPreviousLink, FormNextLink } from "grommet-icons";
import { bindAll } from "../utils";

class TopPagination extends React.Component {
    render() {
        const pagiButtonTheme = {
            button: {
                border: {
                    color: "#555555",
                },
                primary: {
                    color: "#555555",
                },
            },
        };

        var previousPageDisabled = false;
        var previousPage = this.props.page - 1;
        if (this.props.page == 1) {
            previousPageDisabled = true;
            previousPage = undefined;
        }

        var nextPageDisabled = false;
        var nextPage = this.props.page + 1;
        if (this.props.page * this.props.limit >= this.props.count) {
            nextPageDisabled = true;
            nextPage = undefined;
        }

        return (
            <Grommet theme={pagiButtonTheme}>
                <Box
                    direction="row"
                    margin={{
                        bottom: "medium",
                        left: "small",
                        right: "small",
                        top: "small",
                    }}
                    width="xlarge"
                >
                    <Box basis="1/2" align="start">
                        <Button
                            disabled={previousPageDisabled}
                            label="< Newer"
                            onClick={() => {
                                this.props.changePage(previousPage);
                            }}
                        />
                    </Box>
                    <Box basis="1/2" align="end">
                        <Button
                            disabled={nextPageDisabled}
                            label="Older >"
                            onClick={() => {
                                this.props.changePage(nextPage);
                            }}
                        />
                    </Box>
                </Box>
            </Grommet>
        );
    }
}

export default TopPagination;
