import React from "react";
import { Box, Button, CheckBox, Grommet, Layer, Heading, Text } from "grommet";

import { updateAssetFromChildren } from "../helpers/menuHelpers";

export default class SaveModal extends React.Component {
    constructor() {
        super();
        this.state = {
            checked: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.setState({ checked: this.state.checked ? false : true });
    }

    render() {
        const { checked } = this.state;
        return (
            <Grommet>
                <Layer
                    position="center"
                    modal
                    onClickOutside={() => this.props.onClose()}
                    onEsc={() => this.props.onClose()}
                >
                    <Box
                        align="center"
                        justify="center"
                        gap="medium"
                        pad="large"
                    >
                        <Heading level="3">ARE YOU SURE?</Heading>
                        <Text>
                            Saving this will update the menu on the selected
                            theme.
                        </Text>
                        <Button
                            label="Confirm"
                            onClick={() => {
                                this.props.onOpen2();
                                updateAssetFromChildren(
                                    this.props.menuBarChildren,
                                    this.props.themeID,
                                    this.props.themes,
                                    this.props.staging,
                                    checked,
                                    this.props.isMidwest
                                ).then(res => {
                                    if (res == 200) {
                                        const close = this.props.onClose2;
                                        setTimeout(function() {
                                            close();
                                        }, 1500);
                                    } else {
                                        this.props.onClose2();
                                        this.props.onOpen3();
                                    }
                                });
                                this.props.onClose();
                            }}
                        />
                    </Box>
                </Layer>
            </Grommet>
        );
    }
}
