import React from "react";
import {
    Box,
    Button,
    CheckBox,
    Anchor,
    Text,
    Menu,
    DataTable,
    Heading,
    Grommet,
} from "grommet";

import { bindAll, jsonGET, jsonPOST } from "../utils";

import StandardHeader from "./StandardHeader";
import Pagination from "./Pagination";
import TopPagination from "./TopPagination";

export default class Errors extends React.Component {
    componentDidMount() {
        this.getErrorList(
            this.state.pagination.job,
            this.state.pagination.page,
            this.state.pagination.limit
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            error_data: {},
            pagination: {
                name: "",
                job: this.props.match.params.id,
                page: 1,
                count: 0,
                limit: 15,
            },
            error: "",
        };
        this.getErrorList = this.getErrorList.bind(this);
        this.requeue_all = this.requeue_all.bind(this);
        this.requeue = this.requeue.bind(this);
        this.acknowledge = this.acknowledge.bind(this);
    }

    getErrorList(job, page, limit) {
        jsonGET("/api/errors", {
            sync_job: job,
            page: page,
            limit: limit,
            acknowledged: false,
        }).then(response => {
            if (response.status == "success") {
                this.setState({
                    error_data: response.data,
                    pagination: response.data.pagination,
                });
            } else {
                this.setState({
                    error: "fail",
                });
            }
        });
    }

    requeue_all(job) {
        jsonPOST("/api/requeue_all", { job: job }).then(response => {
            if (response.status == "success") {
                console.log("Requeue All Successfull");
            }
        });
    }

    requeue(id) {
        console.log(id);
        jsonPOST("/api/requeue", { id: id }).then(response => {
            if (response.status == "success") {
                console.log("Requeue Successfull");
            }
        });
    }

    acknowledge(id) {
        console.log(id);
        jsonPOST("/api/acknowledge", { id: id }).then(response => {
            if (response.status == "success") {
                console.log("Acknowledge Successfull");
            }
        });
    }

    pageSelect(page) {
        this.setState({
            pagination: {
                name: "",
                page: page,
                limit: this.state.error_data.pagination.limit,
                count: this.state.error_data.pagination.count,
            },
        });
        this.getErrorList(
            this.state.pagination.job,
            page,
            this.state.pagination.limit
        );
    }

    render() {
        const globalTheme = {
            global: {
                font: {
                    // family: "Helvetica"
                },
            },
            button: {
                font: {
                    weight: "bold",
                },
                border: {
                    radius: "8px",
                    color: "#2196f3",
                },
                extend: props => {
                    let extraStyles = "";
                    if (props.primary) {
                        extraStyles = `
                text-transform: uppercase;
              `;
                    }
                    return `
              ${extraStyles}
            `;
                },
            },
        };

        const customButtonTheme = {
            button: {
                border: {
                    radius: "4px",
                    color: "#2196f3",
                },
                padding: {
                    vertical: "1px",
                    horizontal: "4px",
                },
                primary: {
                    color: "#2196f3",
                },
                extend: props => {
                    let extraStyles = "";
                    if (props.primary) {
                        extraStyles = `
                text-transform: uppercase;
              `;
                    }
                    return `
              font-size: 10px;
              font-weight: bold;
              font-family: Helvetica;
              color: white;

              ${extraStyles}
            `;
                },
            },
        };

        const columns = [
            {
                property: "id",
                header: <Text>ID</Text>,
            },
            {
                property: "log_id",
                header: "Run ID",
            },
            {
                property: "endpoint",
                header: "Endpoint",
            },
            {
                property: "data",
                header: "Data",
            },
            {
                property: "error",
                header: "Error",
            },
            {
                property: "requeue",
                header: "Requeue",
                render: datum => (
                    <Grommet theme={customButtonTheme}>
                        <Box pad={{ vertical: "xsmall" }}>
                            {!this.state.error_data.acknowledged ? (
                                <Button
                                    label="Acknowledge"
                                    primary={true}
                                    color="neutral-1"
                                    onClick={() => {
                                        this.acknowledge(datum.id);
                                    }}
                                />
                            ) : null}
                            {datum.endpoint != "" ? (
                                <Button
                                    key={datum.endpoint}
                                    primary={true}
                                    color="neutral-4"
                                    label="Requeue"
                                    margin={{ top: "1px" }}
                                    onClick={() => {
                                        this.requeue(datum.id);
                                    }}
                                />
                            ) : null}
                        </Box>
                    </Grommet>
                ),
            },
        ];

        return (
            <Box>
                <StandardHeader />
                <Grommet theme={globalTheme}>
                    <Box
                        animation={{
                            type: "fadeIn",
                            duration: 2000,
                        }}
                        alignSelf="center"
                        align="center"
                    >
                        <Box
                            border="bottom"
                            margin="small"
                            width="xlarge"
                            height="xxsmall"
                            align="start"
                            direction="row"
                        >
                            <Box align="start" basis="1/2" alignSelf="start">
                                <Heading margin="none" level="2">
                                    {this.state.pagination.name} Errors
                                </Heading>
                            </Box>
                            <Box align="end" basis="1/2" alignSelf="center">
                                <Button
                                    label="Requeue All"
                                    primary={true}
                                    color="neutral-4"
                                    onClick={() => {
                                        this.requeue_all(
                                            this.state.pagination.job
                                        );
                                    }}
                                />
                            </Box>
                        </Box>

                        <TopPagination
                            parent={this}
                            changePage={this.pageSelect.bind(this)}
                            page={this.state.pagination.page}
                            limit={this.state.pagination.limit}
                            count={this.state.pagination.count}
                        />

                        <Box
                            align="center"
                            width="xlarge"
                            margin={{ left: "small", right: "small" }}
                        >
                            <DataTable
                                alignSelf="stretch"
                                sortable={true}
                                columns={columns}
                                data={this.state.error_data.data}
                            />
                        </Box>

                        <Pagination
                            parent={this}
                            changePage={this.pageSelect.bind(this)}
                            page={this.state.pagination.page}
                            limit={this.state.pagination.limit}
                            count={this.state.pagination.count}
                        />
                    </Box>
                </Grommet>
            </Box>
        );
    }
}
