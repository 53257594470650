import React from "react";
import { Box, Button, Heading, Paragraph } from "grommet";

import { bindAll, jsonGET } from "../utils";

export default class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect_url: "#",
        };

        bindAll(this, ["componentDidMount"]);
    }

    componentDidMount() {
        let url = "/api/oauth/redirect";
        let params = {
            next: "/",
        };
        if (
            this.props.location.state != undefined &&
            this.props.location.state.from.pathname != undefined
        ) {
            params = {
                next: this.props.location.state.from.pathname,
            };
        }

        jsonGET(url, params).then(response => {
            this.setState({
                redirect_url: response.data,
            });
        });
    }

    render() {
        return (
            <Box fill={true} align="center" justify="center">
                <Box
                    align="center"
                    justify="center"
                    background={{ color: "light-4" }}
                    pad="large"
                    height="large"
                    width="xlarge"
                    round="small"
                    animation={{
                        type: "fadeIn",
                        duration: 1000,
                    }}
                >
                    <Heading level={1} size="medium" margin="small">
                        Welcome to Theseus
                    </Heading>
                    <Paragraph size="large" textAlign="center">
                        Theseus uses Google to login. To login to Theseus please
                        press the button and select your Northern Brewer Google
                        account. If prompted to give approve permissions, please
                        approve them.
                    </Paragraph>
                    <Button
                        primary
                        color="dark-1"
                        href={this.state.redirect_url}
                        label="Login to Theseus"
                        margin="medium"
                    />
                </Box>
            </Box>
        );
    }
}
