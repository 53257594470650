import React from "react";
import { Box, Button } from "grommet";
import { AddCircle } from "grommet-icons";

import MenuHeading from "./MenuHeading";

export default class MenuBar extends React.Component {
    onDragStart(e, index) {
        this.dragItem = this.props.children[index];
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    }

    onDragOver(index) {
        const dragOverItem = this.props.children[index];
        if (this.dragItem === dragOverItem) {
            return;
        }
        let items = this.props.children.filter(item => item !== this.dragItem);
        items.splice(index, 0, this.dragItem);
        this.props.updateMenuChildrenOrder(items);
    }
    render() {
        return (
            <Box>
                <ul style={{ listStyleType: "none" }}>
                    {this.props.children.map((child, i) => {
                        return (
                            <li
                                key={child.title}
                                onDragOver={() => this.onDragOver(i)}
                                style={{
                                    display: "inline-block",
                                    padding: "0px 5px",
                                }}
                            >
                                <div
                                    draggable
                                    onDragStart={e => this.onDragStart(e, i)}
                                >
                                    <MenuHeading
                                        self={child}
                                        target={this.props.target}
                                        index={i}
                                        onOpen={this.props.onOpen}
                                        updateMenuChildrenOrder={
                                            this.props.updateMenuChildrenOrder
                                        }
                                        menuChildren={this.props.children}
                                    />
                                </div>
                            </li>
                        );
                    })}
                    <Button
                        disabled={this.props.selectedTheme == null}
                        hoverIndicator={true}
                        icon={<AddCircle />}
                        label="Add Item"
                        onClick={() => this.props.onOpen()}
                        plain={true}
                    />
                </ul>
            </Box>
        );
    }
}
